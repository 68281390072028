<template>
  <b-card no-body class="shadow-lg rounded" border-variant="primary">
    <div slot="header">
      <strong>Select Unassigned Device or Create a new Device  {{company? 'for '+company.name : ''}}</strong>
    </div>
      <b-card-body>
        <b-row>
          <b-col sm="5">
            <b-form-group
            label="Search on Device code or uuid"
            label-cols-sm="5"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            >
              <b-input-group size="sm">
                <b-form-input
                  ref="focus"
                  v-model="context"
                  type="search"
                  id="filterInput"
                  placeholder="code or uuid"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label="Per page"
              label-cols-sm="4"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                :plain="true"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button v-if="company" class="pull-right" variant="primary" size="md" :to="`/devicegroup/device/${company.devicegroup_set[0].id}/new`">New Device</b-button>
          </b-col>
        </b-row> 
        <b-table
          striped
          fixed
          show-empty
          ref = "table"
          :current-page="currentPage"
          :per-page="perPage"
          :items="provider"
          :fields="fields"
          selectable
          select-mode="single"
          @row-selected="onSelectedUnassinged"
          >
            <template v-slot:cell(actions)="row">
              <b-button size="sm" variant="success" @click="onAddToCompany(row.item)" append title="Add to Company"><i class="fa fa-plus"></i></b-button>
            </template>
          </b-table>
        <b-row>
          <b-col sm=4></b-col>
          <b-col>
            <b-pagination
                v-if="pagination"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
          </b-col>
          <b-col sm=4></b-col>
        </b-row>
      </b-card-body>
      <b-card-body  v-if="hasCompanyDevices">
        <div class="px-4 py-2" style="background-color: #f0f3f5">
          <span>
            <strong>Company Devices</strong>
          </span>
        </div>
        <b-table
          striped
          fixed
          show-empty
          :items="companyDevices"
          :fields="fields"
          >
            <template v-slot:cell(actions)="row">
              <b-button size="sm" variant="primary" @click="onEdit(row.item)" append title="Edit Device"><i class="fa fa-pencil"></i></b-button>
              <b-button size="sm" variant="danger" @click="onRemove(row.item)" append class="mx-1" title=" Remove from company"><i class="fa fa-times"></i></b-button>
            </template>
          </b-table>
        <b-row>
          <b-col sm=4></b-col>
          <b-col>
            <b-pagination
                v-if="pagination"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
          </b-col>
          <b-col sm=4></b-col>
        </b-row>
      </b-card-body>

    </b-card>
</template>
<script>
import {mapActions } from "vuex"
import ViewExtension from '../../ViewExtension'

export default ViewExtension.extend({
    name: 'CompanyDevices',
    props: {
      companyId:{
        type: String,
        require: false,
      }
    },
    components: {
    },
    data() {
        return {
            fields: [
              {key: 'code', label:'Code', sortable: true},
              {key: 'uuid', label:'Uuid', sortable: true},
              {key: 'name', label:'Name', sortable: true},
              {key: 'actions', label:'Actions'},
              // {key: 'status', label:'Status', sortable: true},
              // {key: 'group.name', label:'Group Id', sortable: true},
              ],
            filter: null, 
            filterOn: ["code","name"],
            pageOptions: [5,10,25,50],
            currentPage: 1,
            perPage: 10,
            sortedBy: 'id',
            sortedDesc: true,
            totalRows: 0,
            context: null,
            company: null,
            companyDevices: [],
            selectedDevice: null,
        }
    },
    watch: {
      context(val){
        this.filterContext()
      }
    },
    computed: {
      pagination(){
        return this.totalRows > this.perPage
      },
      hasCompanyDevices(){
        return this.companyDevices.length > 0
      }
    },
    methods: {
        ...mapActions("meds2go/device", ['filterDevices', 'getCompanyDevices', 'deviceUpdate']),
        ...mapActions("meds2go/company", ['companyDetail']),
        async loadData(){
          if(this.companyId){
            this.loading()
            await this.loadCompanyDevices()
            await this.companyDetail(this.companyId).then(results => {
              this.company = results
            })
            this.stopLoading()
          }

        },
        loadCompanyDevices(){
          this.getCompanyDevices(this.companyId).then(results => {
              // console.log('Company Devices: ', results)
              this.companyDevices = results
            })
        },
        provider(ctx, callback){
            this.filterDevices({
              context: this.context,
              page: ctx.currentPage,
              per_page: ctx.perPage,
              sort_by: ctx.sortBy,
              sort_desc: ctx.sortDesc,
            }).then(results => {
              // console.log('provider: ',results.items)
              callback(results.items)
              this.totalRows = results.tot_rows
          })
        },
        onFiltered(filteredItems) {
          console.log(filteredItems)
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        onSelectedUnassinged(device){
          if (device.length > 0){
            this.selectedDevice = device[0]
          }
          else {
            this.selectedDevice = null
          }
        },
        async onAddToCompany(device){
          this.loading()
          device.group = this.company.devicegroup_set[0].id
          await this.deviceUpdate(device).then(results => {
            this.$refs.table.refresh()
            this.loadCompanyDevices()
          }).catch(errors => {
            console.log(errors)
          })
          this.stopLoading()
        },
        onRemove(device){
          device.group = null
          this.deviceUpdate(device).then(results => {
            this.loadData()
            this.$refs.table.refresh()
          }).catch(errors => {
            console.log(errors)
          })
        },
        onEdit(device){
          this.$router.push(`/device/${device.id}/modify`)
        },
        filterContext(){
          this.$refs.table.refresh()
        }
    },
    mounted() {
      this.loadData()
    }
})
</script>
